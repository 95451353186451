<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('requests.active.detail.title')"
        :sub-title="$t('requests.active.detail.desc')"
    />
    <b-row>
      <b-col sm="8">

        <section
            class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
        >
          <div class="package-details-table bg-white rounded-14">
            <h1 class="package-details-table__title text-font-main text-med-14">
              {{ $t('requests.active.detail.account') }}
            </h1>




            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.order_no') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ detail.tran_id }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.name') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ detail.name }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.order_date') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ moment(detail.created_at).format('ll') }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.transaction_type') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">

                <div class="d-flex mb-0">
                  <subscription-subscription-icon v-if="detail.transaction_type==='subscription'"/>
                  <subscription-downgrade-icon v-if="detail.transaction_type==='downgrade'"/>
                  <subscription-upgrade-icon v-if="detail.transaction_type==='upgrade'"/>
                  <subscription-renew-icon v-if="detail.transaction_type==='renew'"/>

                  <span class="pl-1">{{ $t('subscription.' + detail.transaction_type) }}</span>
                </div>

              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.additional_details') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">

                  <span v-if="detail.prev_transaction">
                      {{$t('financial.from_package')}}
                  {{ $t('subscription.' + detail.prev_transaction.transaction_type) }} -   {{ detail.prev_transaction.subscription_type == '2' ? $t('subscription.monthly2') : $t('subscription.annual2') }}
                  {{$t('financial.to_package')}}

                  </span>
                  {{ $t('subscription.' + detail.transaction_type) }} -   {{ detail.subscription_type == '2' ? $t('subscription.monthly2') : $t('subscription.annual2') }}
                </p>
              </b-col>
            </b-row>



            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('common.period') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">
                  {{ detail.subscription_type == '2' ? $t('subscription.monthly2') : $t('subscription.annual2') }}
                </p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('financial.amount') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ detail.amount }}  {{ $t('common.SR') }}</p>
              </b-col>
            </b-row>


          </div>
        </section>


      </b-col>
    </b-row>
    <provider-reject-modal
        :visible="showModal"
        :provider-id="detail.id"
        @close="showModal = false"
        @onComplete="()=>loadData()"
    ></provider-reject-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from "@/components/WameedBtn";
import ProviderRejectModal from "@/views/pages/requests/providers/components/ProviderRejectModal";

export default {
  components: {
    ProviderRejectModal,
    WameedBtn,

    PageHeader,

  },
  data() {
    return {

      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      detail: 'getFinancialDetail',
    }),

  },

  async created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadDetail: 'loadFinancialDetail',
    }),

    loadData() {
      this.loadDetail(this.$router.currentRoute.params.id);
    },

    reject() {
      this.showModal = true;
    },

    approve() {
      this.approveProvider(this.$router.currentRoute.params.id);

    }

  },
};
</script>
